import React from "react"
import PageLayout from "../components/pageLayout"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <PageLayout seo={{ title: "404: Not Found" }}>
      <h1>Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <p>
        Please try again or <Link to="/">Click here</Link> to go back to the
        home page.
      </p>
    </PageLayout>
  )
}

export default NotFoundPage
